import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { BiSave } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import Loader from '../../Loader';
import { updateDateDeliveryRound } from '../../../actions/deliveryRounds';
import styles from './delivery-round-day-form.module.scss';

import {
  Textarea,
  InputNumber,
  InputFormatPattern,
  ErrorField,
} from '../../../lib/HooksFormFields';
import { formatedDateForObjectDate } from '../../../utils';

const DeliveryRoundDayForm = ({ day, handleSuccess, isLoading }, ref) => {
  const dispatch = useDispatch();
  const {
    control,
    formState: { errors, isDirty },
    trigger,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: {
      target: 0,
    },
  });

  let disabledbutton = false;
  const dateDuJour = new Date();
  const dateJourSelectionne = formatedDateForObjectDate(day.date);
  console.log(dateJourSelectionne);
  console.log(dateDuJour);

  if (dateJourSelectionne > dateDuJour) {
    disabledbutton = true;
  }

  async function onSubmit(data) {
    console.log('onSubmit');
    const updatedTags = day.tags?.map((tag) => ({
      ...tag,
      numberOfDeliveriesTag: data[tag._id] || 0,
    }));
    const res = await updateDateDeliveryRound(dispatch, {
      _id: day._id,
      ...data,
      tags: updatedTags || [],
    });
    if (res.status === 200) {
      if (handleSuccess) handleSuccess();
      const resetValues = {
        start: res.data.day.start || '',
        end: res.data.day.end || '',
        breakHours: res.data.day.breakHours || '',
        comments: res.data.day.comments || '',
        target: res.data.day.target || 0,
      };
      res.data.day.tags.forEach((tag) => {
        resetValues[tag._id] = tag.numberOfDeliveriesTag || 0;
      });
      reset(resetValues);
    }
    return res.status === 200;
  }

  useImperativeHandle(ref, () => ({
    submit: async () => {
      let isValid = true;
      if (isDirty) {
        isValid = await trigger();
      }
      handleSubmit(onSubmit)();
      return isValid;
    },
  }), [isDirty]);

  // INIT FORM VALUES
  useEffect(() => {
    if (!day) return;

    const defaultValues = {
      start: day.start || '',
      end: day.end || '',
      breakHours: day.breakHours || '',
      comments: day.comments || '',
      target: day.target || 0,
    };

    if (day.tags) {
      day.tags.forEach((tag) => {
        defaultValues[tag._id] = tag.numberOfDeliveriesTag || 0;
      });
    }
    reset(defaultValues);
  }, [day]);

  return (
    <div className={styles.form}>
      <div className={styles.fields}>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="start"
            label="Heure de début"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                message: 'Veuillez renseigner l\'heure de début au format ##:## ou une heure valide ',
              },
              validate: {
                isValidTime: (value) => {
                  const [hours, minutes] = value.split(':').map(Number);
                  if (hours < 0 || hours > 23) {
                    return 'Les heures doivent être entre 00 et 23';
                  }
                  if (minutes < 0 || minutes > 59) {
                    return 'Les minutes doivent être entre 00 et 59';
                  }
                  return true;
                },
              },
            }}
            mask="_"
            format="##:##"
            className="secondary"
          />
        </div>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="end"
            label="Heure de fin"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                message: 'Veuillez renseigner l\'heure de fin au format ##:## ou une heure valide ',
              },
              validate: {
                isValidTime: (value) => {
                  const [hours, minutes] = value.split(':').map(Number);
                  if (hours < 0 || hours > 23) {
                    return 'Les heures doivent être entre 00 et 23';
                  }
                  if (minutes < 0 || minutes > 59) {
                    return 'Les minutes doivent être entre 00 et 59';
                  }
                  return true;
                },
              },
            }}
            mask="_"
            format="##:##"
            className="secondary"
          />
        </div>
        <div className={`${styles['container-field']} ${styles.time}`}>
          <InputFormatPattern
            name="breakHours"
            label="Temps de pause"
            control={control}
            placeholder="##:##"
            maxlength={5}
            inline
            widthLabel="150px"
            rules={{
              pattern: {
                value: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
                message: 'Veuillez renseigner le temps de pause ou une heure valide ',
              },
              validate: {
                isValidTime: (value) => {
                  const [hours, minutes] = value.split(':').map(Number);
                  if (hours < 0 || hours > 23) {
                    return 'Les heures doivent être entre 00 et 23';
                  }
                  if (minutes < 0 || minutes > 59) {
                    return 'Les minutes doivent être entre 00 et 59';
                  }
                  return true;
                },
              },
            }}
            mask="_"
            format="##:##"
            className="secondary"
          />
        </div>
        {
          day && ((day?.tags?.length === 0) || (day.tags === undefined)) ? (
            <div className={`${styles['container-field']} ${styles.target}`}>
              <InputNumber
                name="target"
                control={control}
                label="Livraison réalisées"
                inline
                widthLabel="150px"
                className="secondary"
              />
            </div>
          )
            : (
              day && day.tags.map((d) => (
                <div className={`${styles['container-field']} ${styles.target}`} key={d._id}>
                  <InputNumber
                    name={d._id}
                    control={control}
                    label={d.tagName}
                    inline
                    widthLabel="150px"
                    className="secondary"
                  />
                </div>
              ))
            )
        }
        <div className={`${styles['container-field']} ${styles.comments}`}>
          <Textarea
            name="comments"
            control={control}
            label="Ajouter un commentaire"
            widthLabel="150px"
          />
        </div>
      </div>
      <div className={styles.containerSubmit}>
        {!!Object.keys(errors).length
          && [
            'start',
            'end',
            'breakHours',
            'night',
            'restHours',
          ]
            .map((key) =>
              errors?.[key] ? (
                <div className={styles.errorMessage}>
                  <ErrorField
                    key={`error-${key}`}
                    message={errors?.[key].message}
                  />
                </div>
              ) : null)
            .filter((d) => d)[0]}
        <div className={styles.actions}>
          <button
            onClick={handleSubmit(onSubmit)}
            disabled={disabledbutton}
            className={disabledbutton ? 'disabled' : ''}
          >
            {isLoading ? (
              <span className="icon loader">
                <Loader className="secondary" size={20} />
              </span>
            ) : (
              <>
                {isDirty ? (
                  <span className="icon">
                    <BiSave size="24px" />
                  </span>
                ) : (
                  <span className="icon">
                    <BsCheck size="24px" />
                  </span>
                )}
              </>
            )}
            <span>Valider</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(DeliveryRoundDayForm);
